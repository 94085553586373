<template>
  <div
    class="flex min-h-full flex-1 flex-col justify-center py-12 mt-24 sm:px-6 lg:px-8"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-10 w-auto" src="/icon.svg" alt="Healistic" />
      <h2
        class="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900"
      >
        Sign in to Management Panel
      </h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
      <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
        <form class="space-y-6" @submit.prevent="signin">
          <div>
            <label
              for="email"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Email address</label
            >
            <div class="mt-2">
              <input
                id="email"
                v-model="email"
                name="email"
                type="email"
                autocomplete="email"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              for="password"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Password</label
            >
            <div class="mt-2">
              <input
                id="password"
                v-model="password"
                name="password"
                type="password"
                autocomplete="current-password"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <!--   Multi factor auth    -->
          <div v-if="isMFA">
            <label class="block text-sm font-medium leading-6 text-gray-900"
              >Please enter the code from your authenticator app</label
            >
            <div class="mt-2">
              <input
                v-model.trim="verifyCode"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                type="text"
              />
            </div>
          </div>
          <!--          -->
          <div class="flex items-center justify-between">
            <div class="text-sm leading-6">
              <a
                href="#"
                class="font-semibold text-viola-400 hover:text-indigo-500"
                >Forgot password?</a
              >
            </div>
          </div>

          <div>
            <button
              class="flex w-full justify-center rounded-md bg-viola-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-viola-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-viola-400"
            >
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useToastStore } from "../stores/toast.store";

const { showErrorToast } = useToastStore();

definePageMeta({
  layout: false,
  auth: false,
});

const supabase = useSupabaseClient();
const router = useRouter();

const email = ref("");
const password = ref("");

const verifyCode = ref("");
const error = ref("");
const isMFA = ref(false);

async function multiFactorAuth() {
  error.value = "";
  try {
    const { data, error: factorsError } = await supabase.auth.mfa.listFactors();

    if (factorsError) {
      showErrorToast("Error", factorsError.message);
      return;
    }

    const totpFactor = data.totp[0];
    if (!totpFactor) {
      showErrorToast("Error", "No TOTP factors found!");
      return;
    }

    const factorId = totpFactor.id;

    const challenge = await supabase.auth.mfa.challenge({ factorId });

    if (challenge.error) {
      error.value = challenge.error.message;
      showErrorToast("Error", error.value);
      return;
    }

    const challengeId = challenge.data.id;

    const verify = await supabase.auth.mfa.verify({
      factorId,
      challengeId,
      code: verifyCode.value,
    });

    if (verify.error) {
      error.value = verify.error.message;
      showErrorToast("Error", error.value);
      return;
    }
    await router.push("/");
  } catch (err) {
    showErrorToast(
      "Error",
      "An error occurred while processing your request. Please try again later",
    );
  }
}

async function signin() {
  const { error } = await supabase.auth.signInWithPassword({
    email: email.value,
    password: password.value,
  });

  if (error) {
    showErrorToast("Login failed", String(error.message));
  }
  const { data } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel();
  if (!data) {
    showErrorToast(
      "Error",
      "Error retrieving authentication assurance level. Please try again later",
    );
    return;
  }
  if (data.nextLevel === "aal1") {
    await router.push("/");
  }

  if (data.nextLevel === "aal2" && data.nextLevel !== data.currentLevel) {
    if (!isMFA.value) {
      isMFA.value = true;
      return;
    }
    await multiFactorAuth();
  }
}
</script>
